<template>
  <div class="success-claim-content">
    <!-- breadcrumb -->
    <div class="success-claim-content__breadcrumb">
      <BreadCrumb />
    </div>
    <section class="section-content">
      <!-- title Heading -->
      <div class="section-content__heading">
        <h1>Terima Kasih Sudah Mengajukan Klaim</h1>
      </div>
      <!-- subHeading -->
      <div class="section-content__subHeading">
        <h5 v-if="IS_OTOPACK">Tim klaim akan menghubungimu & segera siapkan bukti video unboxing motor Anda</h5>
        <h5 v-else>Klaim Anda akan diproses dalam waktu 3 hari kerja</h5>
      </div>
      <!-- illustration of success claim -->
      <div class="section-content__icon">
        <img
          src="/images/claim/ill_ajukan_klaim_success.svg"
          alt="success-claim-illustration"
        >
      </div>
      <!-- description -->
      <div class="section-content__description">
        <p>Gunakan Nomor Klaim <span>{{ RESULT_CLAIM_ID }}</span> untuk melacak klaim status anda</p>
      </div>
      <!-- button -->
      <div class="section-content__button">
        <Button
          type-class="outline-red"
          :custom-padding="true"
          class="custom-padding"
          text="Lacak Klaim"
          @click="navigateToTrackClaim"
        />
      </div>
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import BreadCrumb from "@/components/new-breadcrumb/BreadCrumb.vue";
import Button from "@/components/new-button/Button.vue";
import mixinMetaInfo from '@/misc/mixinMetaInfo';

export default {
  components: {
    BreadCrumb,
    Button,
  },
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url
    });
  },
  computed: {
    ...mapGetters(['RESULT_CLAIM_ID', 'IS_OTOPACK'])
  },
  mixins: [mixinMetaInfo],
  methods: {
    navigateToTrackClaim() {
      this.$router.push(`/klaim/track?claim_id=${this.RESULT_CLAIM_ID}`)
    }
  }
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
