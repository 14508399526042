<template>
  <div>
    <!-- base content -->
    <section class="base-content">
      <!-- breadcrumb -->
      <div class="base-content__breadcrumb">
        <BreadCrumb />
      </div>
      <!-- end breadcrumb -->

      <!-- termCondition -->
      <div class="base-content__termCondition">
        <!-- Title -->
        <div class="base-content__termCondition__title">
          <h1>Ketentuan Klaim</h1>
        </div>
        <!-- sub title -->
        <div class="base-content__termCondition__subTitle">
          <h3>Proses Pengajuan Klaim Paket</h3>
        </div>
        <!-- illustrations -->
        <div class="base-content__termCondition__illustration">
          <div class="base-content__termCondition__illustration__step-1">
            <p>1</p>
            <div>
              <img
                :src="`/images/klaim/step1.svg`"
                alt="Icon Langkah Satu Lion Parcel"
              >
            </div>
            <div>
              <p>Laporkan <span v-if="widthScreen > 500">Paket</span></p>
              <p>Maks 7 Hari</p>
            </div>
          </div>
          <div class="base-content__termCondition__illustration__step-2">
            <p>2</p>
            <div>
              <img
                :src="`/images/klaim/step2.svg`"
                alt="Icon Langkah Dua Lion Parcel"
              >
            </div>
            <div>
              <p>Lengkapi <span v-if="widthScreen > 500">Formulir</span></p>
              <p>Maks 1 Hari</p>
            </div>
          </div>
          <div class="base-content__termCondition__illustration__step-3">
            <p>3</p>
            <div>
              <img
                :src="`/images/klaim/step3.svg`"
                alt="Icon Langkah Tiga Lion Parcel"
              >
            </div>
            <div>
              <p>Tunggu <span v-if="widthScreen > 500">Proses</span></p>
              <p>Maks 3 Hari</p>
            </div>
          </div>
        </div>
      </div>
      <!-- end termCondition -->

      <!-- content -->
      <div class="base-content__content">
        <div class="base-content__content__no-1">
          <div class="base-content__content__no-1__title">
            <span>1. Laporkan Paket</span>
          </div>
          <div class="base-content__content__no-1__time-service">
            <span>Maks 7 Hari</span>
          </div>
          <div class="base-content__content__no-1__desc">
            <p>
              Laporkan atas kerusakan, kehilangan, dapat diadukan melalui
              <a href="/klaim">https://lionparcel.com/klaim</a> dalam waktu
              paling lambat 7 hari sejak POD atau estimasi kedatangan.
            </p>
          </div>
        </div>
        <div class="base-content__content__no-2">
          <div class="base-content__content__no-2__title">
            <span>2. Lengkapi Formulir</span>
          </div>
          <div class="base-content__content__no-2__time-service">
            <span>Maks 1 Hari</span>
          </div>
          <div class="base-content__content__no-2__desc">
            <p>
              Pengirim/penerima paket yang mengajukan klaim
              <span class="bold">
                harus melengkapi syarat & ketentuan dokumen pengajuan klaim,
              </span>
              paling lambat 1 hari setelah proses ke 1
            </p>

            <ul>
              <p>Dokumen yang harus di lengkapi</p>
              <li
                v-for="(data, idx) in dataDocument"
                :key="idx"
              >
                <div>
                  <span>{{ idx + 1 }}</span>
                  <p v-if="idx === 3">
                    {{ data.document }}
                    <span class="bold">{{ data.inParentheses }}</span>
                  </p>

                  <p v-else>
                    <span class="bold">{{ data.document }}</span>
                    {{ data.inParentheses }}
                  </p>
                </div>
              </li>
            </ul>
            <!-- dropdown -->
            <div class="base-content__content__no-2__desc__dropdown">
              <div
                class="base-content__content__no-2__desc__dropdown__item"
                @click="handleWithInsurance"
              >
                <div>
                  <img
                    v-if="showDataWithInsurance"
                    :src="`/images/chevron_up.svg`"
                    alt="Icon Chevron Atas Lion Parcel"
                  >
                  <img
                    v-else
                    :src="`/images/chevron_down.svg`"
                    alt="Icon Chevron Bawah Lion Parcel"
                  >
                  <span>Jika Dengan Asuransi</span>
                </div>
                <div>
                  <ul :class="showDataWithInsurance ? 'show' : 'hidden'">
                    <li
                      v-for="description in dataWithInsurance"
                      :key="description"
                    >
                      <div>
                        <span>-</span>
                        <p>{{ description }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
              <div
                class="base-content__content__no-2__desc__dropdown__item"
                @click="handleWithoutInsurance"
              >
                <div>
                  <img
                    v-if="showDataWithoutInsurance"
                    :src="`/images/chevron_up.svg`"
                    alt="Icon Chevron Atas Lion Parcel"
                  >
                  <img
                    v-else
                    :src="`/images/chevron_down.svg`"
                    alt="Icon Chevron Bawah Lion Parcel"
                  >
                  <span>Jika Tanpa Asuransi</span>
                </div>
                <div>
                  <ul :class="showDataWithoutInsurance ? 'show' : 'hidden'">
                    <li
                      v-for="description in dataWithoutInsurance"
                      :key="description"
                    >
                      <div>
                        <span>-</span>
                        <p>{{ description }}</p>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="base-content__content__no-3">
          <div class="base-content__content__no-3__title">
            <span>3. Tunggu Proses</span>
          </div>
          <div class="base-content__content__no-3__time-service">
            <span>Maks 3 Hari Kerja</span>
          </div>
          <div class="base-content__content__no-3__desc">
            <p>
              Pengirim/penerima paket yang mengajukan klaim dapat menunggu
              proses hasil klaim dengan estimasi waktu 3 hari kerja sejak
              dokumen diterima oleh Tim Klaim Lion Parcel
            </p>
            <div class="base-content__content__no-3__desc__notes">
              <span>Catatan :</span>
              <ul>
                <li>
                  <div>
                    <span>-</span>
                    <p>
                      Penggantian biaya ganti rugi atas terjadinya klaim dapat
                      dilakukan apabila kiriman dikirim menggunakan standar
                      packing yang berlaku di Lion Parcel.
                    </p>
                  </div>
                </li>
                <li>
                  <div>
                    <span>-</span>
                    <p>
                      Lion Parcel tidak bertanggung jawab atas kerugian material
                      yang disebabkan oleh keterlambatan kiriman.
                    </p>
                  </div>
                </li>
              </ul>
            </div>
            <div class="base-content__tracking-claim-mobile-button">
              <Button
                text="Lacak Klaim"
                class="custom-btn"
                type-class="outline-red"
                :custom-padding="true"
                @click="goToTrackClaim"
              />
            </div>
          </div>
        </div>
      </div>
      <!-- end content -->
      <div class="base-content__button">
        <Button
          text="Lacak Klaim"
          class="custom-btn"
          type-class="outline-red"
          :custom-padding="true"
          @click="goToTrackClaim"
        />
        <Button
          text="Ajukan Klaim Baru"
          class="custom-btn"
          type-class="red"
          :custom-padding="true"
          @click="goToFormClaim"
        />
      </div>
    </section>
    <!-- end base content -->
  </div>
</template>

<script>
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb.vue';
import Button from '@/components/new-button/Button.vue';
import {
  dataDocument,
  dataWithInsurance,
  dataWithoutInsurance,
} from '../app/dataDocument';
import mixinMetaInfo from '@/misc/mixinMetaInfo';

export default {
  components: {
    BreadCrumb,
    Button,
  },
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  mixins: [mixinMetaInfo],
  data() {
    return {
      dataDocument,
      dataWithInsurance,
      dataWithoutInsurance,
      showDataWithInsurance: false,
      showDataWithoutInsurance: false,
      widthScreen: 0,
    };
  },
  created() {
    if (!this.isSsr()) {
      window.addEventListener('resize', this.getWidthScreen);
      this.getWidthScreen();
    }
  },
  mounted() {
    if (!this.isSsr()) {
      window.scrollTo(0, 0);
    }
  },
  destroyed() {
    if (!this.isSsr()) {
      window.removeEventListener('resize', this.getWidthScreen);
    }
  },
  methods: {
    handleWithInsurance() {
      this.showDataWithInsurance = !this.showDataWithInsurance;
    },
    handleWithoutInsurance() {
      this.showDataWithoutInsurance = !this.showDataWithoutInsurance;
    },
    goToTrackClaim() {
      this.$router.push('/klaim/track');
    },
    goToFormClaim() {
      this.$router.push('/klaim/form');
    },
    getWidthScreen() {
      this.widthScreen = !this.isSsr() && window.innerWidth;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "./style.scss";
</style>
