<template>
  <div class="track-claim-content">
    <div class="track-claim-content__bg-hero">
      <BreadCrumb class="track-claim-content__breadcrumb" />
    </div>
    <ImageLazy
      :src="getBannerTrackingClaim"
      alt="banner tracking claim lion parcel"
      class="track-claim-content__banner"
    />
    <div class="track-claim-content__wrapper">
      <!-- tracking box -->
      <div class="track-claim-content__wrapper__box">
        <div>
          <div class="track-claim-content__wrapper__box__label">
            <h1>Lacak Klaim</h1>
            <span>Masukan Nomor Klaim</span>
          </div>
          <div class="track-claim-content__wrapper__box__input">
            <Input
              placeholder="Masukan nomor klaim"
              name="input claim id"
              :value="claim_id"
              @input="trackingClaim"
            />
            <Button
              text="Lacak Klaim"
              class="custom-btn"
              :type-class="Boolean(claim_id) ? 'red' : 'disable'"
              :custom-padding="true"
              @click="getClaim"
            />
          </div>
        </div>
      </div>

      <Loader
        v-if="IS_LOADING_CLAIM"
        color-class="l-grey"
      />

      <!-- Response content -->
      <div v-if="RESULT_CLAIM">
        <div class="track-claim-content__wrapper__detail">
          <h1>Detail Informasi Klaim</h1>
          <div>
            <div>
              <h3>No.STT</h3>
              <span>{{ RESULT_CLAIM.data.package_id }}</span>
            </div>
            <div>
              <h3>Nama Penerima</h3>
              <span>{{ RESULT_CLAIM.data.recipient_name }}</span>
            </div>
            <div>
              <h3>Origin</h3>
              <span>{{ RESULT_CLAIM.data.sender_origin }}</span>
            </div>
            <div>
              <h3>Destinasi</h3>
              <span>{{ RESULT_CLAIM.data.recipient_destination }}</span>
            </div>
          </div>
          <span>Klik ID Klaim untuk melihat detailnya</span>
        </div>

        <!-- track claim id box -->
        <div
          class="track-claim-content__wrapper__track-box"
          @click="showStatus"
        >
          <div>
            <h5>
              ID Klaim <span>{{ RESULT_CLAIM_ID }}</span>
            </h5>
            <img
              :src="`/images/tracking/drop-down.svg`"
              alt="dropdown-icon"
              :class="['dropdown-icon', { active: isShow }]"
            >
          </div>
        </div>

        <!-- status tracking claim -->
        <div class="track-claim-content__wrapper__status">
          <div v-if="isShow">
            <Card
              v-for="(data, index) in RESULT_CLAIM.data.claim_history"
              :key="`${data.claim_id}-${index}`"
              :show-border="
                Boolean(index !== RESULT_CLAIM.data.claim_history.length - 1)
              "
              :img="imageSource(index, data.status.toLowerCase())"
              :title="formatDate(data.history_created_at)"
              :desc="decideDescription(data)"
              :is-b-k-d="false"
              :is-p-o-d="false"
            />
          </div>
        </div>
      </div>
      <div
        v-else-if="isQueryUrl && !IS_LOADING_CLAIM"
        class="track-claim-content__wrapper__not-found"
      >
        <ImageLazy
          :src="`/images/claim_not_found.png`"
          alt="tracking claim not found lion parcel"
          class="claim-not-found"
        />
        <p class="text-result">
          Ups..klaim No. {{ $route.query.claim_id }} tidak ditemukan
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters, mapActions } from 'vuex';
import BreadCrumb from '@/components/new-breadcrumb/BreadCrumb.vue';
import Button from '@/components/new-button/Button.vue';
import Input from '@/components/new-input/Input.vue';
import Card from '@/pages/new-tracking/components/Card.vue';
import mixinMetaInfo from '@/misc/mixinMetaInfo';
import { TimeMapper } from '@/misc/timeMapper';
import Loader from '@/components/new-loader/Loader.vue';
import ImageLazy from '@/components/ImageLazy/index.vue';
import mixinMobile from '@/misc/mixinMobile';
import {
  isStringHasSquareBracket,
  convertLinkDescription,
} from '@/pages/new-tracking/app/util/utils';

export default {
  components: {
    BreadCrumb,
    Input,
    Button,
    Card,
    Loader,
    ImageLazy,
  },
  mixins: [mixinMetaInfo, mixinMobile],
  metaInfo() {
    return this.SEO({
      channel: 'channel',
      title: this.metaInfo.meta_info_title,
      description: this.metaInfo.meta_info_description,
      keywords: this.metaInfo.meta_info_keyword,
      publishedTime: this.metaInfo.meta_info_publish_time,
      image: this.metaInfo.attachment_meta_info_photo_url,
    });
  },
  data() {
    return {
      TimeMapper,
      searchMetaInfo: '_lacak-klaim',
      isShow: false,
      claim_id: null,
      isQueryUrl: false,
      clearContent: {
        result_claim: null,
        claim_id: null,
      },
    };
  },
  computed: {
    getBannerTrackingClaim() {
      return this.isMobileSize
        ? '/images/klaim/hero_banner_mobile.webp'
        : '/images/klaim/hero_banner.webp';
    },
    ...mapGetters([
      'RESULT_CLAIM',
      'MESSAGE_CLAIM',
      'RESULT_CLAIM_ID',
      'IS_LOADING_CLAIM',
      'FIND_CLAIM',
    ]),
  },
  watch: {
    $route: {
      handler(to) {
        const next = to?.query?.claim_id;
        if (!next) {
          this.$store?.commit('FIND_CLAIM', this.clearContent);
          this.isQueryUrl = false;
          this.claim_id = null;
        }
        if (next) {
          this.isQueryUrl = true;
          const payload = {
            claim_id: next,
          };
          this.claim_id = next;
          this.findClaim(payload);
        }
      },
    },
  },
  mounted() {
    if (!this.isSsr()) {
      window.scrollTo(0, 0);
    }
    this.claim_id = this.$route?.query ? this.$route.query.claim_id : '';
    this.getClaim();
  },
  destroyed() {
    this.$store.commit('FIND_CLAIM', this.clearContent);
  },
  methods: {
    ...mapActions(['findClaim']),
    getClaim() {
      if (!this.claim_id) {
        return false;
      }
      const payload = {
        claim_id: this.claim_id,
      };
      this.findClaim(payload);
      if (this.$route.query.claim_id !== this.claim_id) {
        this.$router.push({
          name: 'Lacak Klaim',
          query: { claim_id: this.claim_id },
        });
      }

      this.isQueryUrl = true;
    },
    trackingClaim(value) {
      this.claim_id = value;
    },
    imageSource(idx, src) {
      let status = src;
      switch (status) {
        case 'working':
          status = 'onprogress';
          break;
        case 'reject':
          status = 'rejected';
          break;
        case 'approve':
          status = 'approved';
          break;
      }
      if (idx === 0) {
        return `enable_${status.toLowerCase()}_claim_icon.svg`;
      }
      return `disable_${status.toLowerCase()}_claim_icon.svg`;
    },
    formatDate(date) {
      return (
        `${TimeMapper.SetFormatDate(
          date,
          true,
          [' ', ' ', '-', ':'],
          true,
          false,
          true,
        )} WIB`
      );
    },
    showStatus() {
      this.isShow = !this.isShow;
    },
    decideDescription(data) {
      if (isStringHasSquareBracket(data.description)) {
        const afterFormated = convertLinkDescription(data.description).split(
          ' ',
        );
        const link = [];
        const desc = [];
        for (
          let i = afterFormated.indexOf('<a');
          i < afterFormated.length;
          i++
        ) {
          link.push(afterFormated[i]);
        }
        for (const element of afterFormated) {
          if (element.includes('<')) {
            break;
          }
          desc.push(element);
        }
        return `${link.join(' ')} ${desc.join(' ')}`;
      }

      return data.description;
    },
  },
};
</script>

<style lang="scss" scoped>
@import './style.scss';
</style>
